import React from "react";
import { useInView  } from 'react-intersection-observer';
import { useRecoilState } from "recoil";
import { sideList } from "../Atoms/sideList";
import { sideCurrentAtom } from "../Atoms/sideCurrentAtom";
import { scrollableAtom } from "../Atoms/scrollableAtom";

const CompanySection = () => {
  const [sideCurrent, setSideCurrent] = useRecoilState(sideCurrentAtom)
  const [scrollable, setScrollable] = useRecoilState(scrollableAtom)

  const [ ref, inView, entry ] = useInView({
    threshold: 0,
    rootMargin: '-100px',
    onChange: (inView, entry) => handleOnChange(inView),
  });
  function handleOnChange(entry: boolean){
    if(entry && scrollable) setSideCurrent(sideList[3]);
  }

  return (
      <section id="COMPANY" className="main__section main__section--company" ref={ref}>
        <picture className="bg">
          <source media="(min-width: 600px)" srcSet="img/bg--company.png" />
          <img src="img/bg--company.png" />
        </picture>
        <div className="main__inner">
          <h1 className="main__title">COMPANY</h1>
          <p className="main__subtitle">会社概要</p>
          <dl className="company__overview">
            <dt>社名</dt>
            <dd>T.I.クリエーション株式会社</dd>
            <dt>所在地</dt>
            <dd>〒160-0022<br />東京都新宿区新宿5-10-12<br />岩本ビル601</dd>
            <dt>資本金</dt>
            <dd>1,000,000円</dd>
            <dt>設立</dt>
            <dd>2021年7月</dd>
            <dt>代表取締役</dt>
            <dd>石井 達郎</dd>
          </dl>
        </div>
      </section>
  );
}

export default CompanySection;
