import React, { useState } from "react";
import { useInView  } from 'react-intersection-observer';
import { useRecoilState } from "recoil";
import { sideList } from "../Atoms/sideList";
import { constants } from "../constants";
import { sideCurrentAtom } from "../Atoms/sideCurrentAtom";
import { scrollableAtom } from "../Atoms/scrollableAtom";



const ContactContainer =  ({show=false, locationPath = "/", setLocationPath = (path: any) => {} , navigate = (path: any) => {} }) => {
  function returnTop() {
    setFormComplete(false);
    setFormConfirm(false);
  }
  const [formComplete,setFormComplete] = useState(false);
  const [formConfirm,setFormConfirm] = useState(false);
  const [formDisabled,setFormDisabled] = useState(false);
  const [name,setName] = useState("");
  const onChangeName = (e:React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }
  const [furi,setFuri] = useState("");
  const onChangeFuri = (e:React.ChangeEvent<HTMLInputElement>) => {
    setFuri(e.target.value);
  }
  const [company,setCompany] = useState("");
  const onChangeCompany = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  }
  const [email,setEmail] = useState("");
  const onChangeEmail = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }
  const [emailConfirm,setEmailConfirm] = useState("");
  const onChangeEmailConfirm = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmailConfirm(e.target.value);
  }
  const [content,setContent] = useState("");
  const onChangeContent = (e:React.ChangeEvent<any>) => {
    setContent(e.target.value);
  }
  const [nameError,setNameError] = useState("");
  const [furiError,setFuriError] = useState("");
  const [emailError,setEmailError] = useState("");
  const [emailConfirmError,setEmailConfirmError] = useState("");
  const [contentError,setContentError] = useState("");
  const API_URL = constants.contactApi;
  const handleSubmit = async (event: any) => {
      event.preventDefault();
      setNameError("");
      setFuriError("");
      setEmailError("");
      setEmailConfirmError("");
      setContentError("");
      var formError = false;
      if(name=="") {
        setNameError("お名前を入力してください。");
        formError=true;
      }
      if(furi=="") {
        setFuriError("お名前（フリガナ）を入力してください。");
        formError=true;
      } else if(!furi.match(/^[ァ-ヶー　]+$/)){
        setFuriError("お名前（フリガナ）はフリガナで入力してください。");
        formError=true;
      }
      if(email=="") {
        setEmailError("メールアドレスを入力してください。");
        formError=true;
      } else if(!email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
        setEmailError("メールアドレスを正しく入力してください。");
        formError=true;
      }
      if(emailConfirm=="") {
        setEmailConfirmError("メールアドレス（確認）を入力してください。");
        formError=true;
      } else if(!emailConfirm.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
        setEmailConfirmError("メールアドレス（確認）を正しく入力してください。");
        formError=true;
      } else if(email != emailConfirm){
        setEmailConfirmError("メールアドレスとメールアドレス（確認）が一致しません。");
        formError=true;

      }
      if(content=="") {
        setContentError("お問い合わせ内容を入力してください。");
        formError=true;
      }
      if(!formError) {
        setFormConfirm(true);
      }
  }
  const handleSubmit2 = async (event: any) => {
      event.preventDefault();
      setFormDisabled(true);
      const formElement = event.target;
      const body = new FormData(formElement);
      try  {
        await fetch(API_URL, {
          method: 'POST',
          body: body,
        }).then((res) => {
          res.json().then(data=> {
            if(data["status"] === "mail_sent") {
              setFormDisabled(false);
              setName("");
              setFuri("");
              setCompany("");
              setEmail("");
              setEmailConfirm("");
              setContent("");
              setFormComplete(true);

            } else {
              setFormDisabled(false);
            }
          })
        });
      } catch (e) {
        setFormDisabled(false);
      }
  }
  const handleCancel = async (event: any) => {
    event.preventDefault();
    setFormConfirm(false);
    return false;
  }

  const [sideCurrent, setSideCurrent] = useRecoilState(sideCurrentAtom)
  const [scrollable, setScrollable] = useRecoilState(scrollableAtom)

  const [ ref, inView, entry ] = useInView({
    threshold: 0,
    rootMargin: '-100px',
    onChange: (inView, entry) => handleOnChange(inView),
  });
  function handleOnChange(entry: boolean){
    if(entry && scrollable) setSideCurrent(sideList[4]);
  }

  return (
       <>
      {formComplete == false
      ? 
      <section id="CONTACT" className="main__section main__section--contact" ref={ref}>
          <div className="main__inner">
            <h1 className="main__title">CONTACT</h1>
            <p className="main__subtitle">お問い合わせ</p>
            <div className="main__content main__content--contact">
            {formConfirm
            ?
              <form onSubmit={handleSubmit2}>
                <input type="hidden" name="your-name" value={name}/>
                <input type="hidden" name="furigana" value={furi}/>
                <input type="hidden" name="company_name" value={company}/>
                <input type="hidden" name="email" value={email}/>
                <input type="hidden" name="email_confirm" value={emailConfirm}/>
                <input type="hidden" name="content" value={content}/>
                <div className="contact__row">
                  <div className="contact__head">お名前</div>
                  <div className="contact__content">
                    <p className="contact__input">{name}</p>
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">お名前（フリガナ）</div>
                  <div className="contact__content">
                    <p className="contact__input">{furi}</p>
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">会社名</div>
                  <div className="contact__content">
                    <p className="contact__input">{company}</p>
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">メールアドレス</div>
                  <div className="contact__content">
                    <p className="contact__input">{email}</p>
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">メールアドレス（確認）</div>
                  <div className="contact__content">
                    <p className="contact__input">{emailConfirm}</p>
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">お問い合わせ内容</div>
                  <div className="contact__content">
                    <p className="contact__input" dangerouslySetInnerHTML={{__html: content.split("\n").join('<br />')}} />
                  </div>
                </div>
                <div className="contact__btn contact__btn--confirm">
                  <button className="btn btn--cancel" onClick={handleCancel}>キャンセル</button>
                  <button className="btn btn--confirm" disabled={formDisabled}>送信</button>
                </div>
              </form>
            :
              <form onSubmit={handleSubmit}>
                <div className="contact__row">
                  <div className="contact__head">お名前</div>
                  <div className="contact__content">
                    <input type="text" name="your-name" size={60} value={name} onChange={onChangeName} disabled={formDisabled}/>
                    { nameError != "" &&
                    <span className="error">{nameError}</span>
                    }
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">お名前（フリガナ）</div>
                  <div className="contact__content">
                  {}
                    <input type="text" name="furigana" size={60} value={furi} onChange={onChangeFuri} disabled={formDisabled} />
                    { furiError != "" &&
                    <span className="error">{furiError}</span>
                    }
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">会社名</div>
                  <div className="contact__content">
                    <input type="text" name="company_name" size={60} value={company} onChange={onChangeCompany} disabled={formDisabled} />
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">メールアドレス</div>
                  <div className="contact__content">
                    <input type="text" name="email" size={60} value={email} onChange={onChangeEmail} disabled={formDisabled} />
                    { emailError != "" &&
                      <span className="error">{emailError}</span>
                    }
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">メールアドレス（確認）</div>
                  <div className="contact__content">
                    <input type="text" name="email_confirm" size={60} value={emailConfirm} onChange={onChangeEmailConfirm} disabled={formDisabled}/>
                    { emailConfirmError != "" &&
                      <span className="error">{emailConfirmError}</span>
                    }
                  </div>
                </div>
                <div className="contact__row">
                  <div className="contact__head">お問い合わせ内容</div>
                  <div className="contact__content">
                    <textarea name="content" cols={50} rows={6} onChange={onChangeContent} disabled={formDisabled} value={content} />
                    { contentError != "" &&
                      <span className="error">{contentError}</span>
                    }
                  </div>
                </div>
                <div className="contact__policy">
                  <a href="/policy/" target="_blank">プライバシーポリシー</a>をご確認いただき、ご同意の上お問い合わせください。
                </div>
                <div className="contact__btn">
                  <button className="btn btn--confirm" disabled={formDisabled}>確認</button>
                </div>
              </form>
            }
            </div>
          </div>
        </section>
      :  
      <section id="CONTACT" className="main__section main__section--contact" ref={ref}>
          <div className="main__inner">
            <h1 className="main__title">CONTACT</h1>
            <p className="main__subtitle">お問い合わせ</p>
            <div className="main__content main__content--contact main__content--complete">
              <p className="complete__head">お問い合わせありがとうございます</p>
              <p className="complete__desc">この度はお問い合わせをいただき誠にありがとうございます。<br />お問い合わせいただきました内容を確認の上、ご連絡をさせていただきます。<br />いましばらくお待ちくださいますよう、よろしくお願い申し上げます。</p>
                <div className="contact__btn contact__btn--complete">
                  <button className="btn btn--confirm" onClick={returnTop}>戻る</button>
                </div>
            </div>
          </div>
        </section>
      }
      </>
  );
}

export default ContactContainer;
