import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import { fvAfterShowAtom } from "../Atoms/fvAfterShowAtom"
import { sideList } from "../Atoms/sideList";
import HeaderContainer from "./HeaderContainer";
import SideContainer from "./SideContainer";
import MissionSection from "./MissionSection";
import GreetingSection from "./GreetingSection";
import NewsSection from "./NewsSection";
import CompanySection from "./CompanySection";
import ContactSection from "./ContactSection";

const MainContainer = () => {
  const [fvAfterShow, setFvAfterShow] = useRecoilState(fvAfterShowAtom)

  return (
    <div className={fvAfterShow ? 'container' : 'container hide' } >
      <HeaderContainer />
      <div className="container__border"></div>
      <SideContainer />
      <div className="main" id="scroller">
        <MissionSection />
        <GreetingSection />
        <NewsSection />
        <CompanySection />
        <ContactSection />
      </div>
    </div>
  );
}

export default MainContainer;
