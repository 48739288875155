import React from "react";
import { useInView  } from 'react-intersection-observer';
import { useRecoilState } from "recoil";
import { sideList } from "../Atoms/sideList";
import { sideCurrentAtom } from "../Atoms/sideCurrentAtom";
import { scrollableAtom } from "../Atoms/scrollableAtom";


const GreetingSection = () => {
  const [sideCurrent, setSideCurrent] = useRecoilState(sideCurrentAtom)
  const [scrollable, setScrollable] = useRecoilState(scrollableAtom)

  const [ ref, inView, entry ] = useInView({
    threshold: 0,
    rootMargin: '-100px',
    onChange: (inView, entry) => handleOnChange(inView),
  });
  function handleOnChange(entry: boolean){
    if(entry && scrollable) setSideCurrent(sideList[1]);
  }

  return (
      <section id="GREETING" className="main__section main__section--greeting" ref={ref}>
        <picture className="bg">
          <source media="(min-width: 600px)" srcSet="img/bg--greeting.png" />
          <img src="img/bg--greeting.png" />
        </picture>
        <div className="main__inner">
          <h1 className="main__title">GREETING</h1>
            <p className="main__subtitle">代表挨拶</p>
            <div className="main__content main__content--greeting">
              <p className="greeting__desc">住設メーカーで30余年の営業経験の中で、地域に貢献したいと考えていらっしゃる工務店様やビルダー様が多くいらっしゃったことに感銘を受け、そんなお世話になった方々のお役に立ちたいと起業しました。<br />少子化が進む中で生き残りをかけ地域の工務店様、ビルダー様のポテンシャルは住宅だけでなく地方を活性化できると確信しております。<br />そんな工務店様・ビルダー様と一緒に日本を元気にしていきます。</p>
              <div className="greeting__sign"><img src="img/sign.png" /></div>
          </div>
        </div>
      </section>
  );
}

export default GreetingSection;
