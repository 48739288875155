import React, { useRef, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { animationPlayAtom  } from "../Atoms/animationPlayAtom"
import { fvAfterShowAtom } from "../Atoms/fvAfterShowAtom"

const TopAnimation = () => {
  const [animationPlay, setAnimationPlay] = useRecoilState(animationPlayAtom)
  const [fvAfterShow, setFvAfterShow] = useRecoilState(fvAfterShowAtom)
  const [fvCopyShow, setFvCopyShow] = useState(true);

  useEffect(() => {
    setTimeout(function(){
      setFvAfterShow(true);
    }, 5000);
  }, []);

  return (
    <div className={'container container--fv show'} >
      <picture className={fvCopyShow ? "bg" : "bg hide"} onAnimationEnd={() => setAnimationPlay(false)}>
        <source media="(min-width: 600px)" srcSet="img/bg--fv.png" />
        <img src="img/bg--fv.png" />
      </picture>
      <div className="fv__inner">
        <div>
          { fvCopyShow
           ?
           <>
           <div className="fv__copy fv__copy01"><img src="img/copy01.png" /></div>
           <div className="fv__copy fv__copy02" onAnimationEnd={() => setFvCopyShow(false)}><img src="img/copy02.png" /></div>
           </>
           :
          <div className="fv__logo"><img src="img/logo.png" /></div>
          }
        </div>
      </div>
    </div>
  );
}

export default TopAnimation;
