import React, { createRef } from "react";
//export const sideList: {text: string, ref:  React.RefObject<HTMLElement>}[] = [
//  {text: "MISSION", ref: createRef<HTMLElement>()},
//  {text: "GREETING", ref: createRef<HTMLElement>()},
//  {text: "NEWS", ref: createRef<HTMLElement>()},
//  {text: "COMPANY", ref: createRef<HTMLElement>()},
//  {text: "CONTAT", ref: createRef<HTMLElement>()},
//];
export const sideList: string[] = [
  "MISSION",
  "GREETING",
  "NEWS",
  "COMPANY",
  "CONTACT",
];
