import React, {useEffect} from "react";
import { constants } from "../constants";
import { useInView  } from 'react-intersection-observer';
import { useRecoilState, useRecoilValue, selector } from "recoil";
import { sideList } from "../Atoms/sideList";
import { sideCurrentAtom } from "../Atoms/sideCurrentAtom";
import { newsStateAtom } from "../Atoms/newsStateAtom";
import { scrollableAtom } from "../Atoms/scrollableAtom";
//export const newsState = selector({
//  key: 'wp/news',
//    get: async ({get}) => {
//      return fetch(constants.newsApi, {method: 'GET'})
//      .then(res => res.json())
//}});



const NewsSection = () => {
  const [sideCurrent, setSideCurrent] = useRecoilState(sideCurrentAtom)
  const [scrollable, setScrollable] = useRecoilState(scrollableAtom)
  const [newsState, setNewsState] = useRecoilState(newsStateAtom)

  useEffect(() => {
    fetch(constants.newsApi, {method: 'GET'}).then(res => res.json()).then((d) => setNewsState(d));
  }, []);

  const [ ref, inView, entry ] = useInView({
    threshold: 0,
    rootMargin: '-100px',
    onChange: (inView, entry) => handleOnChange(inView),
  });
  function handleOnChange(entry: boolean){
    if(entry && scrollable) setSideCurrent(sideList[2]);
  }

  return (
      <section id="NEWS" className="main__section main__section--news" ref={ref}>
        <div className="main__inner">
          <h1 className="main__title">NEWS</h1>
          <p className="main__subtitle">お知らせ</p>
          <div className="main__content main__content--news">
            {newsState.map((data: any) => {
              return <NewsItem item={data} key={data.post_date}/>
            })}
          </div>
        </div>
      </section>
  );
}

const NewsItem = ({item = {post_title:"", post_date:"", post_content:""}}: {item: {post_title: string, post_date: string, post_content: string}}) => {

  return (
    item.post_title == ""
      ? <article />
      : <article className="news__block">
          <p className="news__date">{item.post_date}</p>
          <div className="news__content">
            <h2 className="news__title">{item.post_title}</h2>
            {item.post_content.length > 0
              && <p className="news__desc" dangerouslySetInnerHTML={{__html: item.post_content.replace(/\<\!\-\- \/wp\:paragraph \-\-\>\n\n/g, "").replace(/\<\!\-\- \/wp\:paragraph \-\-\>\n/g, "").replace(/\<\!\-\- wp\:paragraph \-\-\>\n/g, "").replace(/\<\!\-\- \/wp\:paragraph \-\-\>/g, "").replace(/\n/g, '<br />').replace(/\<p\>/g, '').replace(/\<\/p\>/g, '')}} />
              //
            }
          </div>
        </article>
  );
}

export default NewsSection;
