import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { sideList } from "../Atoms/sideList";
import { sideCurrentAtom } from "../Atoms/sideCurrentAtom";
import { scrollableAtom } from "../Atoms/scrollableAtom";
import { activeHeaderAtom } from "../Atoms/activeHeaderAtom";

const SideContainer = () => {
  const [activeHeader, setActiveHeader] = useRecoilState(activeHeaderAtom)
  function toggleActiveHeader(){
    setActiveHeader(!activeHeader);
  }

  return (
      <aside className={(activeHeader ? "open" : "")}>
        <div className="logo"><img src="/img/logo.png" /></div>
        <nav>
          <ul>
            {sideList.map((data) => {
              return <LinkItem item={data} key={data} />
            })}
          </ul>
        </nav>
      </aside>
      );
}

const LinkItem = ({item = ""}) => {
  const [sideCurrent, setSideCurrent] = useRecoilState(sideCurrentAtom)
  const [scrollable, setScrollable] = useRecoilState(scrollableAtom)
  const [activeHeader, setActiveHeader] = useRecoilState(activeHeaderAtom)
  function toggleActiveHeader(){
    setActiveHeader(!activeHeader);
  }
  useEffect(() => {
  }, []);
  function handleOnClick(){
    toggleActiveHeader();
    setScrollable(false);
    setTimeout(function(){
      setScrollable(true);
    }, 800);
    setSideCurrent(item);
    smoothScroll(item);
  }
  function smoothScroll(targetSection: string){
    const targetElement = document.getElementById(targetSection);
    if(targetElement == null) return;
    const rect = targetElement.getBoundingClientRect().top;
    const scroller = document.getElementById("scroller");
    if(scroller==null) return;
    const offset = scroller.scrollTop;
    const target = rect + offset;
    scroller.scrollTo({
      top: target,
      behavior: 'smooth',
    });
    const offset2 = window.scrollY;
    const headerElement = document.querySelector("header");
    const headerHight= headerElement ? headerElement.clientHeight : 0 ;
    const target2 = rect + offset2 - headerHight;
    window.scrollTo({ top: target2, left: 0, behavior: 'smooth'  });
  }

  return (
    item == ""
      ? <li />
      : <li className={item == sideCurrent ? "current" : ""} onClick={() => handleOnClick()}>{item}</li>
  );
}

export default SideContainer;
