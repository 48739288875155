import React from "react";
import TopAnimation from "./TopAnimation";
import MainContainer from "./MainContainer";

const Home =() => {
  return (
    <>
      <TopAnimation/>
      <MainContainer/>
    </>
  );
}
export default Home;
