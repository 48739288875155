import React from "react";
import { useInView  } from 'react-intersection-observer';
import { useRecoilState } from "recoil";
import { sideList } from "../Atoms/sideList";
import { sideCurrentAtom } from "../Atoms/sideCurrentAtom";
import { scrollableAtom } from "../Atoms/scrollableAtom";


const MissionSection = () => {
  const [sideCurrent, setSideCurrent] = useRecoilState(sideCurrentAtom)
  const [scrollable, setScrollable] = useRecoilState(scrollableAtom)

  const [ ref, inView, entry ] = useInView({
    threshold: 0,
    rootMargin: '-100px',
    onChange: (inView, entry) => handleOnChange(inView),
  });
  function handleOnChange(entry: boolean){
    if(entry && scrollable) setSideCurrent(sideList[0]);
  }

  return (
      <section id="MISSION" className="main__section main__section--mission" ref={ref}>
          <div className="main__inner">
              <h1 className="main__title">MISSION</h1>
              <p className="main__subtitle">目指す目標</p>
              <div className="main__content main__content--mission">
                  <p className="mission__desc">建物の力で地域を元気にしていきたい。<br />そんな地域密着のビルダー様、工務店様を応援します。<br />日本には世界に誇れる美しい場所がたくさんあります。<br />その素晴らしい日本の原風景を世界の人々に体験して頂くことで過疎が進む地方の活性化に建物の力で貢献していきます.</p>
              </div>
          </div>
      </section>
  );
}

export default MissionSection;
