import React from "react";
import { useRecoilState } from "recoil";
import { activeHeaderAtom } from "../Atoms/activeHeaderAtom";

const HeaderContainer = () => {
  const [activeHeader, setActiveHeader] = useRecoilState(activeHeaderAtom)
  function toggleActiveHeader(){
    setActiveHeader(!activeHeader);
  }

  return (
      <header>
        <div className="header__logo"><img src="/img/logo__header.png" /></div>
        <div className={"header__hamburger" + (activeHeader ? " active" : "")}>
        <a className={"menu-trigger" + (activeHeader ? " active" : "")} onClick={toggleActiveHeader}>
        <span></span>
        <span></span>
        <span></span>
        </a>
        </div>
      </header>
      );
}

//const LinkItem = ({item = ""}) => {
//  const [sideCurrent, setSideCurrent] = useRecoilState(sideCurrentAtom)
//  const [scrollable, setScrollable] = useRecoilState(scrollableAtom)
//  useEffect(() => {
//  }, []);
//  function handleOnClick(){
//    setScrollable(false);
//    setTimeout(function(){
//      setScrollable(true);
//    }, 800);
//    setSideCurrent(item);
//    smoothScroll(item);
//  }
//  function smoothScroll(targetSection: string){
//    const targetElement = document.getElementById(targetSection);
//    if(targetElement == null) return;
//    const rect = targetElement.getBoundingClientRect().top;
//    const scroller = document.getElementById("scroller");
//    if(scroller==null) return;
//    const offset = scroller.scrollTop;
//    const target = rect + offset;
//    scroller.scrollTo({
//      top: target,
//      behavior: 'smooth',
//    });
//  }
//
//  return (
//    item == ""
//      ? <li />
//      : <li className={item == sideCurrent ? "current" : ""} onClick={() => handleOnClick()}>{item}</li>
//  );
//}

export default HeaderContainer;
